$primary-color: #29abe1;
$secondary-color: #ec1e79;
$hero-bkg:#FFF;
$featured-image: url(/images/header2.png);
$icon-color:$primary-color;

$page-header-bkg:#f3cc03;
$page-header:#404040;

$toggle-icon-bar:white;
$line-color:#ededed;

$home-search-bkg:#f3cc03;
$home-search-text:#404040;
$home-search-submit-bkg:#29ABE1;
$home-search-submit-txt:#FFF;


/* header and navbar */
body{
  color:#404040;
  font-family:'Lato', sans-serif;
}
header{
  background-color:#222;
}
.navbar{
  margin-bottom:0;
  min-height:50px;
}
.navbar-brand {
  padding: 10px 0;
  img{
    padding-left:15px;
    padding-top:5px;
    max-width:220px;
  }
}

.navbar-nav>li>a {
  padding-top:25px;
  font-size:18px;
  color: #FFF;
}
.nav>li>a:focus, .nav>li>a:hover{
  background-color : transparent;
  color: #f3cc03;
}
.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color:#222;
}
.navbar-toggle {
  background-color:$secondary-color;
  margin:18px 15px 0 0;

  .icon-bar {
    background-color: $toggle-icon-bar;
  }
}

/* body, main and basic styles */
body{
  color:#666;
  font:16px "Lato", sans-serif;
  line-height: 23px;
  word-wrap: break-word;
}
a{
  color:$primary-color;
}
a:hover, a:active{
  color:#e04590;
}
h1, h2, h3, h4, h5, h6{
  color : $primary-color;
  font-family: 'Montserrat', sans-serif;
  font-weight:700;
}
ul{
  list-style: none;
  -webkit-padding-start:0;
}
p {
  margin:0 0 20px;
}
input[type="text"], input[type="email"], input[type="search"], input[type="password"], input[type="tel"], input[type="url"], input[type="date"], textarea{
  padding: 13px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  width: 90%;
  margin: 0 0 20px;
  background-color: #f9f9f9;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-size:18px;
}
input[type="reset"], input[type="button"], input[type="submit"]{
  background-color: $primary-color;
  border:1px solid $primary-color;
  text-align: center;
  text-transform: uppercase;
  padding: 13px 35px 13px 35px;
  border-radius: 25px;
  margin: 0 0 10px;
  box-shadow: 0 0 6px #666;
  text-shadow: none;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
  color: #FFF;
}
main {
  padding:30px 0 40px;
}

/*posts*/
.post-excerpt, .post{
  padding: 0 0 40px;
  margin-bottom: 20px;
  clear:both;
  border-bottom:1px solid $line-color;
  .meta{
    padding: 10px 0;
    border-top: 1px solid $line-color;
    border-bottom: 1px solid $line-color;
    margin-bottom: 15px;
    font-size:12px;
  }
  p.title{
    font-family: 'Montserrat', sans-serif;
    font-size:28px;
    line-height: 40px;
    margin:20px 0 15px;
    display:inline-block;
    color: $secondary-color;
    font-weight:700;
    a{
      color:$secondary-color;
      font-weight:700;
    }
    a:hover{
      text-decoration: none;
    }
  }
  p {
    max-width:98%;
    line-height:26px;
    margin-bottom:30px;
  }
  .excerpt{
    display:inline-block;
    width:88%;
  }
  a.button {
    background-color: #29abe1;
    border: 1px solid #29abe1;
    text-align: center;
    text-transform: uppercase;
    padding: 13px 35px 13px 35px;
    border-radius: 25px;
    margin: 0 0 10px;
    box-shadow: 0 0 6px #666;
    text-shadow: none;
    font-weight: 400;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    font-size: 16px;
    color: #FFF;
  }
  a.button:hover{
    text-decoration: none;
    background-color:$secondary-color;
  }
}
.pagination {
  display: block;
  min-height: 50px;
  text-align: center;
  margin-bottom:50px;
  a {
    color: $primary-color;
    padding: 5px 10px;
    text-align: center;
    margin: 5px;
    border-radius: 4px;
    display:inline-block;
  }
  a:hover {
    color:$secondary-color;
    text-decoration: none;
  }

}

/* sidebar */
aside h3.widget-title, aside h3, aside h2.title {
  font-size: 26px;
  line-height: 23px;
  padding-top:10px;
  padding-bottom: 15px;
  margin:20px 0 30px;
  font-weight: 700;
  color:$secondary-color;
  letter-spacing: 2px;
  border-bottom: 1px solid $line-color;
}
aside ul{
  li{
    padding:6px 0;
    a{
      color: $primary-color;
      i{
        color:#f8d53c;
        padding-right:5px;
      }
    }
  }
}
/* footer */
.before-footer{
  background-color:$primary-color;
  padding: 20px 0 20px;
  text-align:center;
  margin:0 auto;
  a{
    display: inline-block;
    padding:10px;
    color:#FFF;
  }

}
footer{
  background-color:#272727;
  padding: 20px 0 20px;
  color:#939393;
  font-size: 14px;
  line-height: 21px;
  text-align:center;
  .container{
    margin: auto;
    max-width: 100%;
  }
  .icon-top {
    height: 40px;
  }
  .icon-top.red-text .fa{
    color:#ec1e79;
    font-size: 35px;
  }
  .icon-top.green-text .fa{
    color:#5ed037;
  }
  .company-details{
    margin-bottom:20px;
  }
}


@media screen and (max-width:830px) {
  footer .copyright{
    padding-bottom: 10px;
    display: block;
  }
}
/*section specific code */
/*styles for page-header partial*/
section.page-title-wrap {
  background-color: $page-header-bkg !important;
  padding               :20px 0;
  text-align            : center;
  background-attachment : fixed;
  background-position   : center center;
  background-size       : cover;
  margin-top            : -1px;

  h1.page-title {
    font-size: 30px;
    line-height: 37px;
    color: $page-header;
    margin:0 auto;
    text-shadow:1px 1px 2px #888;
    text-transform: uppercase;
  }
}
/* hero */
.home-header-wrap{
  background-image: url(/images/city-bg.png);
  background-repeat:no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  .header-content-wrap {
    background: transparent;
    padding: 110px 0 200px;
    h1 {
      text-transform: none;
      text-shadow: 1px 1px 18px #666;
      text-align: center;
      color: #FFF;
      line-height: 65px;
      font-size: 50px;
    }
    .buttons{
      text-align: center;
      margin-top: 45px;
      .btn{
        text-transform: uppercase;
        display: inline-block !important;
        text-align: center;
        margin: 10px;
        padding: 13px 35px 13px 35px;
        font-size:18px;
        border-color:transparent;
      }
      .red-btn{
        background: #ec1e79;
        box-shadow: 0 0 6px #666;
        border-radius: 25px;
      }
      .green-btn{
        background: #29abe1;
        box-shadow: 0 0 6px #666;
        border-radius: 25px;
      }
    }
  }
}
@media screen and (max-width:440px) {
  .home-header-wrap{
    .header-content-wrap {
      h1 {
        font-size: 34px;
        line-height:50px;
      }
    }
  }
}
/*home search only needed when including the search-jobs partial*/
section#home-search{
  text-align:center;
  background-color:$home-search-bkg;
  padding: 20px 0 50px;
  h3{
    color:$home-search-text;
    padding-top: 0;
    font-size: 2em;
    text-shadow:1px 1px 2px #888;
    margin-bottom:20px;
    text-transform: uppercase;
  }
  form{
    max-width: 75%;
    text-align: center;
    margin: auto;
    padding:0;
  }
  input[type="text"]{
    width:37%;
    margin-right:1%;
  }
  input[type="submit"]{
    background-color:$home-search-submit-bkg;
    border:1px solid $home-search-submit-bkg;
    color:$home-search-submit-txt;
    border-radius: 25px;
    font-size:16px;
    text-transform: uppercase;
    display:inline-block;
    box-shadow: 0 0 6px #666;
  }
}
@media screen and (max-width:782px) {
  section#home-search form {
    max-width : 90%;
    input[type="text"]{
      width:100%;
    }
  }
}


/*home listings*/
.jobs {
  padding: 0;
  display: flex;
  max-width:1170px;
  margin:10px auto 20px;
  text-align:center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  h3{
    color: #404040;
    padding-bottom: 10px;
    line-height: 40px;
    position: relative;
    display: inline-block;
    font-size: 45px;
    text-transform: uppercase;
    text-align:center;
    margin:30px auto;
    line-height: 50px;
  }
  section{
    flex-basis: 24%;
    padding: 1.5%;
    margin: 10px 0;
    vertical-align: top!important;
    display: inline-block;
    background: #f1f1f1!important;
    border-radius: 10px;
    text-align: left;
  }
  h2.Title {
    padding-bottom: 10px;
    margin-top:0;
    line-height:24px;
    height:26px;
    overflow: hidden;
    a {
      font-family: 'Montserrat', sans-serif;
      color: #29abe1;
      font-size: 18px;
      text-transform: capitalize;
      font-weight:700;
    }
  }
  a.Company{
    display: block;
    color: #ec1e79;
    font-size:14px;
  }
  .TimePosted {
    display:block;
    color:#999;
    font-size:12px;
  }
  .snippet {
    display:block;
    a.More{
      display: block;
      padding: 10px;
      margin-top: 10px;
      background-color: #29abe1;
      color: #FFFFFF;
      width: 100px;
      text-align: center;
      border-radius: 25px;
    }
  }
}
@media screen and (max-width:782px) {
  .jobs {
    section{
      flex-basis:90%;
      justify-content: center;
      margin: 0 auto 20px;
      padding:5%;
    }
  }
}

/* home features */
.home-features{
  background: #272727;
  color:#FFF;
  padding:50px 0;
  .header h2{
    color:#FFF;
    padding-bottom:15px;
    text-align: center;
    margin:15px auto 30px;
    font-size: 50px;
    text-transform: uppercase;
  }
  .big-intro{
    text-align: right;
    font-weight: 300;
    font-size: 60px;
    line-height: normal;
    margin-top:-15px;
  }
  p{
    text-align: left;
    color: #939393;
    font-size: 18px;
    line-height: 25px;
    a{
      color:#cbba61;
    }
  }
  .skill{
    clear: both;
  }
  .skill .skill-count{
    display: inline-block;
    height: 64px;
    margin-top: 3px;
    float: left;
    margin-right: 15px;
    margin-bottom: 25px;
  }
  .skill p{
    font-size:14px;
    line-height:20px;
    color: #8f8f8f;
  }
  .section-legend{
    text-transform: uppercase;
    label{
      padding-top:5px;
      display:inline-block;
    }
  }
}
@media screen and (max-width: 892px){
  .home-features {
    .big-intro{
      text-align:left;
      margin-bottom:20px;
    }
  }
}
/* signup message */
.subscribe{
  background-color: #f3cc03;
  padding: 50px 0 60px;
  text-align: center;
  h2{
    padding-bottom: 10px;
    line-height: 40px;
    position: relative;
    display: inline-block;
    font-size: 45px;
    text-transform: uppercase;
    margin:15px auto;
    font-weight:700;
    color:#404040;
    text-align: center;
  }
  input[type="submit"]{
    width: 90%;
    background-color: #ec1e79;
    box-shadow: 0 0 6px #666;
    font-size: 16px;
    border-radius: 25px;
  }
  a{
    color:#ec1e79;
  }
}